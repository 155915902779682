import { graphql, useStaticQuery } from 'gatsby'

export const useMeta = () => {
  const data = useStaticQuery(graphql`
    query {
      meta: sanityMeta {
        title
        description
        url
        keywords
        image {
          asset {
            url
          }
        }
      }
    }
  `)

  const { meta } = data || {}
  return meta
}
